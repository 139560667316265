import React, { useEffect, useState } from 'react';
type TLetter = typeof import('../letters.json')[0];

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export function Letter(props: TLetter) {
  const [lettersIndex, setLettersIndex] = useState(0);
  const [unlocked, setUnlocked] = useState(false);
  const [message, setMessage] = useState(props.message);

  useEffect(() => {
    if (!unlocked) return;
    setTimeout(() => {
      if (lettersIndex < message.length) {
        let jumpIndex = 1;
        if (message[lettersIndex] === '{') {
          const endIndex = message.indexOf('}', lettersIndex);
          const val = message.substr(lettersIndex + 1, endIndex - lettersIndex - 1);

          switch (val) {
            case 'enter': {
              const br = `<br /><br />`;
              setMessage(message.replace(`{${val}}`, br));
              jumpIndex = br.length;
              break;
            }
          }
        }
        setLettersIndex(lettersIndex + jumpIndex);
      }
    }, getRandomInt(20, 200));
  }, [unlocked, lettersIndex]);

  const handleInputChange = event => {
    if (event.target.value && event.target.nextSibling) {
      event.target.nextSibling.focus();
    } else if (!event.target.value && event.target.previousSibling) {
      event.target.previousSibling.focus();
    }

    checkLock();
  };

  const checkLock = () => {
    const answerChars = props.questions.map(question => question.answer[question.codeIndex].toLowerCase());

    let tempAnswer = '';
    document.querySelectorAll('.code-group .form-control').forEach((input: HTMLInputElement) => {
      tempAnswer += input.value.toLowerCase();
    });

    const answer = props.codeOrder.reduce((a, c) => a + answerChars[c].toLowerCase(), '');
    if (answer === tempAnswer) setUnlocked(true);
  };

  return (
    <>
      {!unlocked && (
        <div className="list-group letter-questions">
          {props.questions.map((question, qi) => (
            <div key={qi} className="list-group-item list-group-item-action">
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{question.question}</h5>
              </div>
              <div className="mb-1 input-group">
                {question.answer.split('').map((char, index) => (
                  <input
                    key={index}
                    type="text"
                    onChange={handleInputChange}
                    maxLength={1}
                    className={
                      'form-control text-center ' +
                      (index === question.codeIndex ? 'border-danger' : '')
                    }
                  />
                ))}
              </div>
            </div>
          ))}
          <div className="list-group-item list-group-item-action">
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1 text-danger font-weight-bold">Code:</h5>
            </div>
            <div className="mb-1 input-group code-group">
              {props.questions.map((q, index) => (
                <input
                  key={index}
                  type="text"
                  onChange={handleInputChange}
                  maxLength={1}
                  className={'form-control text-center'}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {unlocked && (
        <div className="letter">
          <p className="message">
            <span
              dangerouslySetInnerHTML={{
                __html: message.substring(0, lettersIndex)
              }}
            ></span>
            <span className="blinking-cursor">|</span>
          </p>
        </div>
      )}
    </>
  );
}
