import React from 'react';

interface IAppContext {
  agent?: string;
  setAgent?: (string) => void;
};

export const AppContext = React.createContext<IAppContext>({});
AppContext.displayName = 'AppContext';

export const useApp = () => React.useContext(AppContext);
