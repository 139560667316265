import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Entrance } from './pages/entrance';
import { AppContext } from './app-context';
import { Letters } from './pages/letters';

function App() {
  const [agent, setAgent] = useState<string>();

  return (
    <AppContext.Provider
      value={{
        agent,
        setAgent
      }}
    >
      {!agent && <Entrance />}
      {!!agent && <Letters />}
    </AppContext.Provider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
