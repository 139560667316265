import React from 'react';
import { useApp } from '../app-context';

export function Entrance() {
  const { setAgent } = useApp();
  let agentInput = null;
  const handleSubmit = () => {
    setAgent(agentInput.value.toUpperCase());
    return false;
  };

  return (
    <div className="container entrance">
      <div className="row">
        <div className="col min-vh-100 d-flex align-items-center justify-content-center">
          <form onSubmit={handleSubmit} action="#">
            <p className="text-center">What is your secret agent name?</p>
            <input
              className="form-control"
              type="text"
              placeholder="Secret agent name"
              ref={input => {
                agentInput = input;
              }}
            />
            <button
              type="submit"
              className="btn btn-block btn-outline-danger mt-4"
            >
              Enter
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
