import React, { useState } from 'react';
import { useApp } from '../app-context';
import letters from '../letters.json';
import { Letter } from '../components/letter';
type TLetter = typeof import('../letters.json')[0];

export function Letters() {
  const { agent, setAgent } = useApp();
  const agentLetters = letters.filter(letter => letter.to === agent);
  const [selectedLetter, setSelectedLetter] = useState<TLetter>();

  const handleLetterClick = (letter: TLetter) => {
    setSelectedLetter(letter);
    return false;
  };

  const handleGoBack = () => {
    setAgent('');
    return false;
  };

  return (
    <div className="container letters">
      <div className="row">
        <div className="col">
          <h1 className="mt-5">Agent Letters</h1>
          <p className="lead d-flex justify-content-between">
            <span>Welcome Agent {agent}</span>
            <small>
              <a onClick={handleGoBack} href="#" className="text-danger">
                Sign off
              </a>
            </small>
          </p>
          <hr />

          {!agentLetters.length && (
            <>
              <div className="alert alert-danger" role="alert">
                Sorry, no letters have been sent to you agent {agent}. Please send me a letter to engage in further correspondence.
              </div>
              <p>
                <button
                  type="button"
                  onClick={handleGoBack}
                  className="btn btn-block btn-outline-danger mt-4"
                >
                  Go Back
                </button>
              </p>
            </>
          )}

          {!!agentLetters.length && (
            <>
              {!selectedLetter && (
                <>
                  <p>
                    You'll need to collect secret information to unlock these letters, so be
                    prepared!
                  </p>
                  <p>If your agent name is ever compromised, please inform me via letter.</p>
                  <p>a</p>
                </>
              )}
              {!!selectedLetter && <Letter {...selectedLetter}></Letter>}

              <hr />

              <div className="list-group mb-5">
                {agentLetters.map((letter, index) => (
                  <div key={index} className="list-group-item list-group-item-action">
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1 font-weight-bold">{letter.date}</h5>
                      <a href="#" onClick={() => handleLetterClick(letter)} className="text-danger">
                        Open Letter
                      </a>
                    </div>
                    <p className="mb-1"></p>
                    <small>{letter.questions.length} questions to unlock</small>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
